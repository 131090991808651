import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {IamRestService} from './iam-rest.service';

// RXJS
import {Observable} from 'rxjs';

// NGRX
import {Store} from '@ngrx/store';
import { Organization } from '../../models/organization/organization.interface';
import { ReferenceOrganizationType } from '../../models/references/organization-type.interface';

// Models

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  selectOrganization(organizationId: string): Observable<void> {
    const url = `${this.baseUrl}/users/:userId/current_organization/${organizationId}`;
    return this.put(url, {});
  }

  getOrganizations(): Observable<Organization[]> {
    const url = `${this.baseUrl}/organizations/:userId`;
    return this.get(url);
  }

  getRoles(orgId: string): Observable<ReferenceOrganizationType[]> {
    const url = `${this.baseUrl}/organizations/:userId/${orgId}/roles`;
    return this.get(url);
  }
}
