import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserStateModel } from '../../store/state/user.state';
import { AuthenticationGuard } from './authentication.guard';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  @Select(state => state.user)
  user$: Observable<UserStateModel>;

  constructor(private auth: AuthenticationGuard, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // expected roles will be passed from the route config
    const expectedRoles = route.data['allowedRoles'];
    return this.userContainsRole(expectedRoles);
  }

  userContainsRole(expectedRoles: string[]): Observable<boolean> {
    return this.user$.pipe(
      map(user => {
        // Check if user is logged-in
        if (Boolean(user.userInfo)) {
          const userRoles = user.userInfo.business_information?.business_roles;
          // If the userInfo object contains roles
          if (userRoles && userRoles.length > 0) {
            // Format roles to array with code values: ["role1", "role2"]
            const roles = userRoles.map(role => role.organization_role.code);
            // Check if the user role exists in the route roles
            return roles.some(role => expectedRoles?.includes(role));
          }
        }
        return user.loading ? true : false;
      })
    );
  }
}
