import { UserInfo } from '../../models/userinfo.interface';

export class AuthenticationSuccess {
  static readonly type = '[User] Authentication success';
  constructor(public userInfo: UserInfo) {}
}

export class AuthenticationError {
  static readonly type = '[User] Authentication error';
}

export class PerformAuthentication {
  static readonly type = '[User] Check login with backend';
  constructor(public code?: string) {}
}
