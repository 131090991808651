import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {IamRestService} from './iam-rest.service';

// RXJS
import {Observable} from 'rxjs';

// NGRX
import {Store} from '@ngrx/store';

// Models
import { QuarterlyReview } from '../../models/quarterly-review/quarterly-review.interface';

@Injectable({
  providedIn: 'root',
})
export class QuarterlyReviewService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  updateQuarterlyReview(members: string[]): Observable<{}> {
    const url = `${this.baseUrl}/periodic_validation/:userId`;
    return this.post(url, members);
  }

  getQuarterlyReview(): Observable<QuarterlyReview> {
    const url = `${this.baseUrl}/periodic_validation/:userId`;
    return this.get(url);
  }

}
