import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {IamRestService} from './iam-rest.service';

// RXJS
import {Observable} from 'rxjs';

// NGRX
import {Store} from '@ngrx/store';
import { ReferenceTypeObjects } from '../../models/references/reference-types.interface';

// Models

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  getReferenceData(
    type: string,
    active = undefined
  ): Observable<ReferenceTypeObjects[]> {
    let url = `${this.baseUrl}/ref/${type}`;
    if (typeof active === 'boolean') {
      url += `?active=${active}`;
    }
    return this.get(url);
  }
}
