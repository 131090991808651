import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

// RXJS
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

// NGXS
import { Select, Store } from '@ngxs/store';
import { ErrorMessage } from '../../models/error-message.interface';
import {
  AuthenticationError,
  PerformAuthentication,
} from '../../store/actions/user.actions';
import { UserInfo } from '../../models/userinfo.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  @Select(state => state.user.userInfo)
  userinfo$: Observable<UserInfo>;

  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.checkStoreAuthentication().pipe(
      mergeMap(storeAuth => {
        if (storeAuth) {
          return of(true);
        }
        return this.checkApiAuthentication();
      }),
      map(storeOrApiAuth => {
        if (!storeOrApiAuth) {
          this.authenticationFailed();
          return false;
        }
        return true;
      }),
      catchError(error => {
        this.authenticationFailed(error);
        return of(false);
      })
    );
  }

  checkStoreAuthentication(): Observable<boolean> {
    return this.userinfo$.pipe(
      map(userinfo => Boolean(userinfo) && Object.keys(userinfo).length > 0)
    );
  }

  checkApiAuthentication(): Observable<boolean> {
    return this.store
      .dispatch(new PerformAuthentication(''))
      // @ts-ignore
      .pipe(map(store => Boolean(store.user.userInfo)));
  }

  authenticationFailed(error?: ErrorMessage): void {
    if (!Boolean(error)) {
      error = {
        Error: 'Er is een fout opgetreden bij het inloggen',
      };
    }

    this.store.dispatch(new AuthenticationError());
  }
}
